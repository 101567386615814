// import React, { useEffect, useRef } from 'react';
// import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';
// import { createChart, CrosshairMode, PriceScaleMode, LineStyle } from 'lightweight-charts';

// const SERIES = [
//     // 20-period correlation series
//     {
//         key: 'correlations20',
//         type: 'line',
//         scale: 0,
//         buttonLabel: 'GTI',
//         chartOptions: {
//             title: 'GTI',
//             color: 'rgb(241,0,0)', // Red
//             lineWidth: 1,
//             priceScaleId: 'right',
//             priceLineVisible: false,
//         }
//     },
//     // 30-period correlation series
//     // {
//     //     key: 'correlations30',
//     //     type: 'line',
//     //     scale: 0,
//     //     buttonLabel: '30 Trend',
//     //     chartOptions: {
//     //         title: '30 Trend',
//     //         color: 'rgb(0,241,0)', // Green
//     //         lineWidth: 1,
//     //         priceScaleId: 'right',
//     //         priceLineVisible: false,
//     //     }
//     // },
//     // // 40-period correlation series
//     // {
//     //     key: 'correlations40',
//     //     type: 'line',
//     //     scale: 0,
//     //     buttonLabel: '40 Trend',
//     //     chartOptions: {
//     //         title: '40 Trend',
//     //         color: 'rgb(0,0,241)', // Blue
//     //         lineWidth: 1,
//     //         priceScaleId: 'right',
//     //         priceLineVisible: false,
//     //     }
//     // }
// ];

// const HORIZONTAL_LINES = [
//     { price: 1, color: 'white', title: '1' },
//     { price: -1, color: 'white', title: '-1' },
//     { price: -0.80, color: 'yellow', title: '-0.70' },
//     { price: 0, color: 'red', title: '0' }
// ];

// const TrendChartComponent = () => {
//     const correlations = useWebSocketDataStore(state => state.correlations);
//     const chartContainerRef = useRef();
//     const chartRef = useRef();
//     const seriesRef = useRef({});

//     useEffect(() => {
//         if (!correlations || Object.keys(correlations).length === 0 || !chartContainerRef.current) {
//             console.log('No data available or chart container not ready');
//             return;
//         }

//         // Check if data is available for all series
//         if (correlations.correlations20.length === 0 || correlations.correlations30.length === 0 || correlations.correlations40.length === 0) {
//             console.log('Not rendering chart because no data is available for all series');
//             return;
//         }

//         if (!chartRef.current) {
//             console.log('Creating chart');
//             chartRef.current = createChart(chartContainerRef.current, {
//                 width: window.innerWidth * .75,
//                 height: window.innerHeight * .50,
//                 alignLabels: true,
//                 timeScale: {
//                     rightOffset: 12,
//                     barSpacing: 0,
//                     shiftVisibleRangeOnNewBar: false,
//                     fixLeftEdge: true,
//                     rightBarStaysOnScroll: true,
//                     leftBarStaysOnScroll: true,
//                     borderVisible: false,
//                     borderColor: "#000000",
//                     timeVisible: true,
//                     secondsVisible: false,
//                 },
//                 leftPriceScale: {
//                     borderVisible: false,
//                     visible: false,
//                     mode: PriceScaleMode.Normal, // Enable manual scaling
//                     scaleMargins: {
//                         top: 0.1,
//                         bottom: 0.1,
//                     }
//                 },
//                 rightPriceScale: {
//                     borderVisible: false,
//                     mode: PriceScaleMode.Normal,
//                     scaleMargins: {
//                         top: 0.1,
//                         bottom: 0.1,
//                     }
//                 },
//                 grid: {
//                     vertLines: {
//                         color: 'rgba(42, 46, 57, 0)',
//                     },
//                     horzLines: {
//                         color: '#000000',
//                     },
//                 },
//                 layout: {
//                     background: {
//                         color: '#000000'
//                     },
//                     textColor: '#d1d4dc',
//                 },
//                 crosshair: {
//                     mode: CrosshairMode.Normal,
//                 },
//             });

//             // Disable all scaling and scrolling
//             chartRef.current.applyOptions({
//                 handleScale: {
//                     axisPressedMouseMove: {
//                         time: false,
//                         price: false,
//                     },
//                     mouseWheel: false,
//                     pinch: false,
//                 },
//                 handleScroll: {
//                     horzTouchDrag: false,
//                     vertTouchDrag: false,
//                     mouseWheel: false,
//                     pressedMouseMove: false,
//                 },
//             });

//             chartRef.current.timeScale().setVisibleLogicalRange({ from: 0, to: 390 });

//             // Create and set data for each series
//             SERIES.forEach(seriesConfig => {
//                 const series = chartRef.current.addLineSeries(seriesConfig.chartOptions);
//                 const sortedData = [...correlations[seriesConfig.key]].map((dataPoint, index) => ({
//                     time: dataPoint.time !== 0 ? dataPoint.time : correlations[seriesConfig.key][0].time + index * 60, // Ensure time is set correctly
//                     value: dataPoint.value,
//                 }));
//                 series.setData(sortedData.sort((a, b) => a.time - b.time));
//                 seriesRef.current[seriesConfig.key] = series;
//             });

//             // Add individual line series for horizontal lines
//             HORIZONTAL_LINES.forEach(line => {
//                 const lineSeries = chartRef.current.addLineSeries({
//                     color: line.color,
//                     lineWidth: 1,
//                 });
//                 lineSeries.setData([{ time: 0, value: line.price }, { time: 390, value: line.price }]);
//             });

//             const handleResize = () => {
//                 let newWidth, newHeight;

//                 if (window.innerWidth <= 1080) {
//                     newWidth = window.innerWidth * 0.87;
//                     newHeight = window.innerHeight * 0.20;
//                 } else {
//                     newWidth = window.innerWidth * 0.70;
//                     newHeight = window.innerHeight * 0.20;
//                 }

//                 chartRef.current.resize(newWidth, newHeight);
//                 const timeScale = chartRef.current.timeScale();
//                 timeScale.setVisibleLogicalRange({ from: 0, to: 390 });

//             };

//             window.addEventListener('resize', handleResize);
//             handleResize();

//             return () => {
//                 window.removeEventListener('resize', handleResize);
//                 chartRef.current.remove();
//                 chartRef.current = null;
//             };
//         } else {
//             // Update the data for each series when correlations change
//             const updateChartData = () => {
//                 SERIES.forEach(seriesInfo => {
//                     const series = seriesRef.current[seriesInfo.key];
//                     if (series) {
//                         const latestDataPoint = correlations[seriesInfo.key][correlations[seriesInfo.key].length - 1];
//                         if (latestDataPoint) {
//                             series.update(latestDataPoint);
//                         }
//                     }
//                 });
//             };

//             updateChartData();
//         }
//     }, [correlations]);

//     if (!correlations || Object.keys(correlations).length === 0 || correlations.correlations20.length === 0) {
//         console.log('Not rendering chart because no data is available');
//         return null; // Don't render the chart if there's no data
//     }

//     return (
//         <div>
//             <h4 style={{ color: '#B18800', marginLeft: '20px' }}>
//                 SPX Gamma Trend:
//                 <span style={{ fontSize: '12px', marginLeft: '10px' }}>
//                     -.8 to -1= No Trend,  0 to -.8=  Moderate Trend, Above 0= Strong Trend
//                 </span>
//             </h4>
//             <div ref={chartContainerRef} style={{ paddingLeft: '70px', paddingRight: '0px' }} />
//         </div>
//     );
// };

// export default TrendChartComponent;













